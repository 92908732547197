@import "~assets/scss/1-variables/classic";

.paginate {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .count {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    .count-title {
      margin-right: 8px;
    }
  }

  .page-item {
    & .page-link {
      padding: 7px 14px !important;
      border-color: $gray-500 !important;
      color: #6e6e6e;
      &:hover {
        background-color: #f0f0f0 !important;
      }
    }

    &.active .page-link {
      background-color: #f0f0f0 !important;
      color: #6e6e6e !important;
    }
  }
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

.page-item:last-child .page-link {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}
