@import "~assets/scss/1-variables/classic";

.settings {
  & .card-body {
    width: 600px;
  }

  &__save {
    padding: 0 22px 22px 22px;
    border-bottom: solid 1px $gray-300 !important;
  }
}