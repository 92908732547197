@import "~assets/scss/1-variables/classic";

.cut {
  display: block;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &__hover {
    display: none;
    position: absolute;
    left: -13px;
    top: -9px;
    padding: 8px 12px;
    border-radius: 7px;
    background-color: #fff;
    border: solid 1px #ced4da;
    text-align: left;
    z-index: 1;
  }

  &:hover {
    overflow: inherit;

    .cut__hover {
      display: block;
    }

    & span {
      user-select: none;
    }
  }
}
