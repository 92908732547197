.printAssemblyList {
  display: none;
  width: 1200px;
  font-size: 14px;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;


  table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
  }

  td,
  th {
    max-width: 250px;
    padding: 10px;
    overflow: hidden;
    border: solid 1px black;
  }

  .infoBlock {
    padding: 3px;
  }

  .number {
    font-size: 22px;
    margin-bottom: 15px;
    margin-left: 40px;
  }

  .headItem {
    display: flex;
    margin-bottom: 8px;
    font-size: 14px;

    &.transport {
      font-weight: bold;
    }
  }

  .headItem__title {
    min-width: 180px;
  }

  tr.red-bg {
    background: rgb(255 0 0 / 0.3);
  }

  .time {
    font-size: 18px;
  }

  .nowrap {
    white-space: nowrap;
  }

  .bottom {
    font-size: 16px;
  }

  .route {
    font-size: 18px;
    font-weight: bold;
  }
}

@media print {
  .printAssemblyList {
    display: block;
  }
}