@import "~assets/scss/1-variables/classic";

.autocomplete {
  position: relative;

  &__result-list-wrapper {
    position: absolute;
    border: solid 1px #ced4da;
    border-radius: 4px;
    margin-top: 7px;
    max-height: 300px;
    width: 100%;
    overflow-y: auto;
    box-shadow: 0 0 10px -3px #00000038;
    background: $white;
    z-index: 2;
  }

  &__spinner {
    position: absolute;
    left: calc(100% - 30px);
    top: calc(50% - 8px);
    background: $white;
    z-index: 4;

    &.search {
      left: calc(100% - 134px);
    }
  }

  &__result-list-item {
    padding: 5px;
    cursor: pointer;

    strong {
      font-weight: 700;
    }

    &:hover {
      background-color: rgba(183, 217, 255, 0.4);
    }
  }

  &__input-big {
    display: flex;
    justify-content: space-between;
    height: 57px;
    padding: 10px;
    border: solid 1px $gray-400;
    border-radius: 7px;

    & .form-control {
      border: none;
    }
  }

  &__button {
    width: 116px;
    border: solid 1px $black !important;
    background-color: $gray-200 !important;
    border-radius: 5px !important;

    &:hover {
      background-color: $gray-primary !important;
    }
  }
}
