.supplyPrintLabel {
  // display: none;
  width: 300px;
  height: 212px;
  padding: 0px;
  margin: 46px auto 15px -40px;
  transform: rotateZ(90deg);
  transform-origin: center;
  background-color: rgb(250 250 250);
  text-align: center;

  table {
    width: 100%;
  }

  td {
    padding: 3px 5;
    overflow: hidden;
  }

  .providerName {
    font-size: 20px;
    line-height: 1.1;
  }

  .date {
    font-size: 20px;
  }

  .place {
    font-size: 20px;
  }
}

@media print {
  .printLabel {
    display: block;
  }
}