@import "~assets/scss/1-variables/classic";

.user-cell {
  display: flex;

  & img {
    margin-right: 12px;
  }
}

.supplies {
  .bold span {
    font-weight: 600 !important;
  }

  .bailor {
    margin-right: 5px;
    color: $red;
  }
}
