@mixin modal-variant($color) {
  .modal-content {
    background: $color;
    color: color-yiq($color);
  }

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    color: $white;
  }
}
