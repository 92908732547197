@import "~assets/scss/1-variables/classic";

.select-driver {
    position: absolute !important;
    width: 200px;
    top: 3px;
    z-index: 1;
}

.removeDriver {
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 5px;
    top: calc(50% - 9px);
    height: 16px;
    width: 16px;
    border-radius: 3px;
    color: red;
    background-color: $white;
}

.selectDriver {
    width: 100%;
    display: block;

    &:hover {
        & .removeDriver {
            display: flex;
        }
    }
}