.spinner {
  display: flex;
  align-items: center;
  &.overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 4px;
    background: rgba(0, 0, 0, .2);
    z-index: 1;
  }
}