.barcodePrint {
  display: none;
  width: 350px;
  margin-bottom: 20px;
  text-align: center;

  &__article {
    font-size: 23px;
  }

  &__brand {
    font-size: 23px;
  }

  &__name {
    font-size: 12px;
  }

  &.display {
    display: block;
  }
}

@media print {
  .barcodePrint {
    display: block;
  }
}