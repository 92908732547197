.Toastify__toast {
  border-radius: 7px !important;
}

.Toastify__toast-theme--colored {
  &.Toastify__toast--success {
    background: $green;
  }
  &.Toastify__toast--error {
    background: $red2;
  }
}
