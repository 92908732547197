// Theme colors
$blue:    #5b7dff !default;
$indigo:  #6610f2 !default; //Подсветка товара в пути
$purple:  #6f42c1 !default;
$pink:    #a180da !default;
$red:     #FF0000 !default; //Основной красный
$red2:    #FF5C5C !default; //Красные кнопки
$red3:    #ff8484 !default; //Красный в проценке из файла
$red4:    #ffdada !default; //Ошибка бэкграунд
$orange: 		#FF945D !default;  // Было fd7e14
$yellow:  #fcc100 !default;
$green:   #22AD16 !default; //Основной Зеленый цвет
$green2:  #c7ffc0 !default; //Яркий зеленый цвет
$teal:    #20c997 !default;
$cyan:    #e7f1f7 !default; //Фон заголовков таблиц
$gray-primary:  #EBEBEB !default; //Основной серый цвет кнопок
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #F8F8F8 !default; //Светло серый
$gray-300: #ededed !default; //Вторая обводка таблиц
$gray-400: #ced4da !default; //Основная обводка инпутов
$gray-500: #C1C1C1 !default; //Обводка блоков светло серый
$gray-600: #DEDEDE !default; //Обводка элементов дропдауна
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;

$black: #000 !default;

$primary:          $gray-primary !default;
$secondary:        $pink !default;
$tertiary:         $green !default;
$success:          $green !default;
$info:             $blue !default;
$warning:          $yellow !default;
$danger:           $red !default;
$light:            $gray-100 !default;
$dark:             #F4F4F4 !default;

// Body
$body-bg: $white !default;

// Sidebar general
$sidebar-bg: $dark !default;
$sidebar-brand-icon-color: $primary !default;

// Sidebar link
$sidebar-link-color: $black !default;

// Hamburger
$hamburger-width-top: 18px !default;
$hamburger-width-middle: 24px !default;
$hamburger-width-bottom: 14px !default;
