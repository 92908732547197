@import "~assets/scss/1-variables/classic";
@import "~assets/scss/1-variables/app";

.supply {
  &__form {
    width: 900px;
  }

  & .row:not(:first-child) {
    margin-top: 12px;
  }

  &__button-save {
    border-bottom: solid 1px $gray-300 !important;
  }

  &__date-block {
    display: flex;
    align-items: center;
    margin-left: 37px;

    .date {
      width: 110px;
    }

    & .form-label {
      margin-right: 14px;
    }
  }

  &__carried-out-button {
    display: flex;
    align-items: center;
    margin-left: 15px;
  }

  &__accepted {
    margin-left: 15px;
    white-space: nowrap;
  }

  &__not_accepted {
    color: $red;
    cursor: pointer;
  }

  &__labels {
    left: 17px;
    top: -92px;
    position: absolute;
    width: 215px;
    padding: 12px;
    border-radius: 5px;
    background-color: white;
    box-shadow: $box-shadow;

    &-wrapper {
      position: relative;
      display: inline-block;
    }

  }

  .green {
    color: $green;
  }
}