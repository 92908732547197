html {
  scroll-behavior: smooth;
}

body {
  overflow-y: scroll;
  opacity: 1 !important;
  font-size: 14px;
  font-weight: 500;
  color: $black;
}

a,
.link {
  color: $orange;
  cursor: pointer;

  &:hover {
    color: $orange;
    opacity: 0.8;
  }
}

label {
  margin-bottom: 3px;
  font-weight: 500;
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
  html {
    overflow-x: hidden;
  }
}