@import "~assets/scss/1-variables/classic";

.sklad-hover {
  position: relative;
  cursor: pointer;

  &__popup {
    position: absolute;
    width: 350px;
    min-height: 100px;
    bottom: 22px;
    padding: 8px 12px;
    border-radius: 7px;
    background-color: $white;
    border: solid 1px $gray-400;
    text-align: left;
    z-index: 1;
  }
}
