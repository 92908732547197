.order-picking {
  &__toolbar {
    margin-top: 20px;
  }

  & .button-block {
    display: flex;
    justify-content: flex-start;

    & .btn {
      margin-right: 10px;
    }
  }
}
