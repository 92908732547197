@import "~assets/scss/1-variables/classic";

.selectCellBlock {
  position: absolute !important;
  width: 200px;
  top: 3px;
  z-index: 1;
}

.selectCell {
  width: 100%;
  display: block;
  height: 20px;

  & .selectCellText {
    display: none;
  }

  &:hover .selectCellText {
    display: block;
  }
}