@import "~assets/scss/1-variables/classic";

.priceList {
  & .card-body {
    max-width: 600px;
  }

  & .row:not(:first-child) {
    margin-top: 6px;
  }

  &__save {
    padding: 5px 22px 22px 22px;
  }
}
