@import "~assets/scss/1-variables/classic";

.user-cell {
  display: flex;

  & img {
    margin-right: 12px;
  }
}

.bold span {
  font-weight: 600 !important;
}

.cyrillic {
  color: $red2;
}
