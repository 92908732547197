.chiefSigner {
  width: 100%;
  margin: auto;
  font-size: 8px;
  border-collapse: unset;

  &>tbody>tr>td:last-child {
    border-bottom: solid 1px black;
  }

  & tr td {
    vertical-align: bottom;
  }

  &>tbody>tr>td:first-child {
    width: 86px;
    vertical-align: top;
    border-right: solid 1px black;
  }

  & .shiefSignerTable {
    td {
      width: 10%;
    }
  }

  & td {
    padding-bottom: 10px;
    position: relative;
  }

  & td span {
    font-size: 6px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    display: block;
    border-top: 1px solid #000;
  }
}

.basis-table {
  width: 100%;
  margin: auto;
  font-size: 8px;


  & td {
    position: relative;
    padding-bottom: 10px;
  }

  & td:nth-child(2) {
    width: 70%;
  }

  & td:nth-child(3) {
    width: 10px;
  }

  & span {
    border-top: 1px solid #000;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 6px;
  }

}

.data-table {
  width: 100%;
  margin: auto;
  font-size: 8px;


  & td {
    position: relative;
    padding-bottom: 10px;
  }

  & td:nth-child(2) {
    width: 70%;
  }

  & td:nth-child(3) {
    width: 10px;
  }

  & span {
    border-top: 1px solid #000;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 6px;
  }
}

.transport {
  width: 100%;
  margin: auto;

  & tbody>tr>td:nth-child(1) table {
    max-width: 14cm;
    border-right: solid 1px black;

    & td:last-child {
      padding-right: 10px;
    }
  }

  & table {
    font-size: 8px;
    border-collapse: unset;

    & tr:nth-child(2) td,
    & tr:nth-child(5) td,
    & tr:nth-child(7) td,
    & tr:nth-child(9) td {
      position: relative;
      padding-bottom: 10px;
    }

    & td {
      padding: 2px;
    }

    & td:nth-child(4) {
      width: 10px;
    }

    & span {
      border-top: 1px solid #000;
      position: absolute;
      bottom: 0px;
      left: 0;
      width: 100%;
      text-align: center;
      font-size: 6px;
    }

    & tr:nth-child(2) td:nth-child(1),
    & tr:nth-child(2) td:nth-child(2) {
      width: 30%;
    }

    & tr:nth-child(2) td:nth-child(3) {
      width: calc(40% - 10px);
    }

  }
}