.form-control-no-border {
  border: 0;
  border-radius: 0;
  box-shadow: none;

  &:focus {
    box-shadow: none;
    outline: 0;
  }
}

.form-label{ //new class
	font-size: 13px;
	font-weight: 600;
	color: $gray-900;
	line-height: 23px;
	margin-bottom: 5px;
}

.form-control {
  height: 36px;
  border-radius: 7px;
	font-size: 13px;
	padding: 12px;
  &:focus {
    border-color: $white;
  }

  &.date {
    padding-left: 32px;
    background: url(~assets/img/calendar.svg) 13px center no-repeat;
  }

  &::placeholder {
    font-weight: 400;
    font-size: 13px;
    color: $gray-650 !important;
  }
}

.input-group {
  & > .btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  & > .btn:not(:last-child):not(:first-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.button-input-group.input-group {
  margin-top: 0.34rem;
}

.row {
  margin-right: -7px;
  margin-left: -7px;
}

form .row:not(:first-child) {
  margin-top: 6px;
}

.elem-field .sub {
  font-size: 12px;
}

.with-label {
  margin-top: 28px;
}

.col-xxl,
.col-xxl-auto,
.col-xxl-12,
.col-xxl-11,
.col-xxl-10,
.col-xxl-9,
.col-xxl-8,
.col-xxl-7,
.col-xxl-6,
.col-xxl-5,
.col-xxl-4,
.col-xxl-3,
.col-xxl-2,
.col-xxl-1,
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding-right: 7px;
  padding-left: 7px;
}

label > span {
  color: red;
}
