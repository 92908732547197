@import "~assets/scss/1-variables/classic";

.cart {
  &__error {
    background-color: $red;
  }

  & h3 {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 500;
  }

  &__right-block {
    width: 300px;
  }

  & .card-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__inline-block {
    display: flex;
    align-items: center;

    & label {
      margin-right: 7px;
    }

    & > div {
      width: 100%;
    }
  }

  .count {
    height: 31px;
    text-align: center;
  }
}
