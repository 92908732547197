@import "~assets/scss/1-variables/classic";

.order-picking {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 150px;
    height: 150px;
    justify-content: center;
    margin-right: 10px;
    margin-bottom: 10px;
    border: solid 1px $gray-300;
    background-color: $gray-primary;
    border-radius: 7px;
    cursor: pointer;

    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:hover {
      background-color: $orange;
      color: $black;
      text-decoration: none;
    }

    img {
      width: 40px;
    }
  }

  &__number {
    margin-bottom: 8px;
    font-size: 15px;
    font-weight: 600;
  }

  &__customer {
    color: $black;
    font-size: 12px;
    text-align: center;
  }

  .filter {}


}