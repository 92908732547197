@import "~assets/scss/1-variables/classic";

.docDiadoc {
  .exist {
    opacity: 0.3;

    td {
      cursor: default;
    }

  }
}