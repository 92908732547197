@import "~assets/scss/1-variables/classic";

.addresses {
  position: relative;
  border-bottom: solid 1px $gray-300 !important;

  &__header {
    position: absolute;
    width: 100%;
    height: 43px;
    background-color: $cyan;
  }

  & .table {
    width: 100%;
    min-width: 950px;
    margin-left: 22px;
    position: relative;

    & td,
    & th {
      padding-right: 20px;
      padding-left: 20px;
    }

    & td {
      border-bottom: solid 1px $gray-300 !important;
    }

    & td:not(:last-child) {
      position: relative;
      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 0;
        height: 20px;
        right: 0;
        top: calc(50% - 10px);
        border-left: solid 1px $gray-300 !important;
      }
    }

    & td:first-child,
    & th:first-child {
      padding-left: 0px;
    }
  }

  & .card-body {
    max-width: 600px;
  }

  & .buttons {
    margin-top: 20px;
  }

  &__save {
    margin-right: 12px;
  }
}
