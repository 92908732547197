@import "~assets/scss/1-variables/classic";

.order-to-provider {
  &__form {
    width: 900px;
  }

  & .row:not(:first-child) {
    margin-top: 12px;
  }

  &__button-save {
    border-bottom: solid 1px $gray-300 !important;
  }

  &__date-block {
    display: flex;
    align-items: center;
    margin-left: 37px;

    .date {
      width: 140px;
    }

    & .form-label {
      margin-right: 14px;
    }
  }

  &__carried-out-button {
    display: flex;
    align-items: center;
    margin-left: 15px;
  }
}
