@import "~assets/scss/1-variables/classic";

.user-cell {
  display: flex;

  & img {
    margin-right: 12px;
  }
}

.bold span {
  font-weight: 600 !important;
}

.delivery-list {
  .row-expansion-style {
    padding: 8px 0 !important;
  }

  .mainRow {
    background-color: #e4e4e4;
  }

  .deliveryListStatus {
    border-radius: 3px;
    overflow: hidden;
    padding: 0 2px;
    color: white;
  }

  .actions {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 3px;
    border-radius: 4px;
    height: 30px;
    background-color: #fff;
    box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.2);
  }

  .printOrderPicking {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;

    & .green {
      color: $green;
    }
  }

  .route {
    & .changeRoute {
      display: none;
    }

    &:hover {
      & span {
        display: none;
      }

      & .changeRoute {
        display: block;
      }
    }
  }
}