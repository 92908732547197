@import "~assets/scss/1-variables/classic";

.dashboard-toolbar {

  &__import-buttons {
    display: flex;

    & > .btn:first-child {
      margin-right: 10px;
    }
  }

  &__with-reset {
    position: relative;
  }

  &__reset-upload {
    position: absolute;
    width: 36px;
    height: 36px;
    right: 0;
    border: none !important;
    background-color: transparent;
    outline: 0 !important;

    &:hover {
      background-color: transparent;
    }
  }

  &__red-button {
    background-color: $red2 !important;
  }
}
