@import "~assets/scss/1-variables/classic";

.nomenclature-modal {
  &__id {
    font-size: 13px;
    margin-left: 10px;
    color: $gray-400;
  }

  & .row:not(:first-child) {
    margin-top: 6px;
  }

  & .prices {
    padding-top: 10px;
    border-top: solid 1px $gray-300;
  }

  &__image {
    width: 100%;
    overflow: hidden;
    border-radius: 12px;

    & img {
      width: 100%;
    }
  }

  &__miniatures {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  &__miniature {
    position: relative;
    width: 94px;
    overflow: hidden;
    margin-top: 10px;
    border-radius: 7px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    &:not(:first-child) {
      margin-left: 7px;
    }

    &:nth-child(4n + 1) {
      margin-left: 0px;
    }

    & img {
      width: 100%;
    }

    &.selected {
      border: solid 1px $black;
    }
  }

  & .history-button {
    margin-left: 10px;
  }

  &__add {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 75px;
    margin-top: 10px;
    margin-left: 7px;
    border-radius: 7px;
    border: solid 1px $gray-300;
    outline: 0 !important;
  }

  &__remove {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    top: 0;
    width: 20px;
    height: 20px;
    padding: 0;
    border: none;
    outline: 0 !important;
    background-color: $black;
  }

  &__barcode {
    display: flex;
    justify-content: center;

    &-img {
      display: flex;
      flex-direction: column;
      gap: 8px;
      justify-content: center;
    }

    & svg {
      width: 100%;
    }
  }

  @media print {
    &__barcode-img svg {
      transform: scale(1.5);
      transform-origin: left;
    }
  }

  &__group {
    margin-bottom: 5px;
  }

  &__category {
    display: inline-block;
    padding: 1px 4px;
    margin: 3px 5px;
    background: $gray-200;
    border: solid 1px $gray-300;
    border-radius: 4px;
  }

  .cell {
    .react-select-container {
      width: calc(100% - 40px);
    }

    &__showmodal {
      width: 36px;
      margin-left: 5px;
    }
  }

  .cellList {
    &>div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2px 5px;
      margin-bottom: 5px;
      background-color: $gray-200;
      border-radius: 3px;


      & button {
        border: none;
        background: none;
      }
    }
  }

  .add-cell {
    position: absolute;
    width: 230px;
    padding: 20px;
    background-color: $white;
    border: solid 1px $gray-200;
    border-radius: 6px;
    z-index: 1;

    & input {
      margin-bottom: 5px;
    }

    & .d-flex button:last-child {
      margin-left: 5px;
    }
  }

  .barcode {
    &__generate {
      width: 36px;
      margin-left: 5px;
    }
  }
}