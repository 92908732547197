.printReturnToProvider {
  // display: none;
  width: 710px;
  font-size: 13px;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;

  & table {
    width: 100%;
    border-collapse: collapse;
  }

  .date {
    font-size: 18px;
  }

  .title {
    margin-top: 100px;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }

  td,
  th {
    padding: 6px 10px;
  }

  .products {
    margin-bottom: 20px;
    td, 
    th {
      border: 1px solid #000;
    }
  }

  // .title {
  //   font-size: 26px;
  //   font-weight: 900;
  // }

  // .customerName {
  //   font-size: 18px;
  //   font-weight: 900;
  // }

  // .orderNumber {
  //   font-size: 26px;
  //   font-weight: 700;
  // }

  // .pickup {
  //   font-size: 50px;
  //   font-weight: 600;
  //   text-align: center;
  //   margin-top: 30px;
  // }
}

@media print {
  .printReturnToProvider {
    display: block;
  }
}