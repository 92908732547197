.card {

  >.dataTables_wrapper .table.dataTable,
  >.table,
  >.table-responsive .table,
  >.table-responsive-sm .table,
  >.table-responsive-md .table,
  >.table-responsive-lg .table,
  >.table-responsive-xl .table {
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    margin-bottom: 0;

    td:first-child,
    th:first-child {
      border-left: 0;
      padding-left: $spacer * 1.25;
    }

    td:last-child,
    th:last-child {
      border-right: 0;
      padding-right: $spacer * 1.25;
    }

    tr:first-child td,
    tr:first-child th {
      border-top: 0;
    }

    tr:last-child td {
      border-bottom: 0;
    }
  }

  .card-header+.table {
    border-top: 0;
  }
}

.react-bootstrap-table {
  padding: 0 22px;

  & .react-bootstrap-table {
    padding: 0;
  }
}

.table-action {
  a {
    color: $gray-600;

    &:hover {
      color: $gray-900;
    }
  }

  .feather {
    width: 18px;
    height: 18px;
  }
}

.table>tbody>tr>td {
  vertical-align: middle;
}

.card>.dataTables_wrapper {
  .table.dataTable {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .dataTables_info {
    padding: 1rem 1.25rem;
  }

  .dataTables_paginate {
    padding: 0.6rem 1.25rem;
  }
}

.dt-bootstrap4 {
  width: calc(100% - 2px);
}

.table {
  color: $black;

  & .right {
    text-align: right;
  }

  & .center {
    text-align: center;
  }

  & .editable {
    cursor: pointer;
  }

  th {
    font-weight: 500 !important;
  }

  .bold {
    font-weight: 600;
  }

  &.pointer tr {
    cursor: pointer;
  }

  & .green {
    color: $green;
  }

  & .green-bg {
    background-color: green2;
  }

  & .red {
    color: $red;
  }

  & .red-bg {
    background-color: $red;
    color: $white;

    & .cut__hover {
      color: red;
    }
  }
}

.table {

  th,
  td {
    padding: 8px;
    white-space: nowrap;
    font-size: 12px;
  }

  // th{
  // 	text-overflow: ellipsis;
  // 	overflow: hidden;
  // }
}

.table {
  .react-select-container .react-select__control {
    height: auto;
  }

  .react-select__dropdown-indicator {
    margin-right: 0;
  }

  .btn {
    padding: 2px 8px;
    height: auto;
    font-size: 12px;
  }

  .status {
    height: auto;
  }

  .count {
    height: auto;
  }

  .form-control {
    padding: 2px 5px;
    height: 25px;
  }

  .action-table.size-big {
    width: 25px;
    height: 25px;
  }

  .btn-link {
    padding: 0;
  }

  .action-table {
    display: inline-flex;
    margin-left: 5px;
  }
}

.table thead th {
  color: $black;
  border: none;
  user-select: none;
  position: relative;

  &:before {
    content: "";
    display: block;
    border: 1px solid $gray-400;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 4px);
    height: calc(100% - 6px);
    border-radius: 5px;
  }

  &:first-child {
    &:before {
      left: 0;
      transform: translate(0, -50%);
      width: calc(100% - 2px);
    }
  }

  &:last-child {
    &:before {
      left: auto;
      right: 0;
      transform: translate(0, -50%);
      width: calc(100% - 1px);
    }
  }
}

.table {
  tbody {
    tr {
      &:first-child {
        td {
          &:before {
            border-top: 1px solid $gray-400;
          }

          &:first-child {
            &:before {
              border-radius: 5px 0 0 0;
            }
          }

          &:last-child {
            &:before {
              border-radius: 0 5px 0 0;
            }
          }
        }
      }

      &:last-child {
        td {
          &:first-child {
            &:before {
              border-radius: 0 0 0 5px;
            }
          }

          &:last-child {
            &:before {
              border-radius: 0 0 5px 0;
            }
          }
        }
      }
    }
  }

  tfoot {
    tr {
      td {
        &:before {
          border-top: 1px solid $gray-400;
        }

        &:first-child {
          &:before {
            border-radius: 5px 0 0 5px;
          }
        }

        &:last-child {
          &:before {
            border-radius: 0 5px 5px 0;
          }
        }
      }
    }
  }

  td {
    position: relative;

    &:before {
      content: "";
      display: block;
      border-bottom: 1px solid $gray-400;
      border-right: 1px solid $gray-400;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }

    &:first-child {
      &:before {
        border-left: 1px solid $gray-400;
      }
    }
  }
}