
.user-cell {
  display: flex;

  & img {
    margin-right: 12px;
  }
}

.bold span {
  font-weight: 600 !important;
}
