@import "~assets/scss/1-variables/classic";

.estimate {
  width: 100%;
  padding: 7px 5px 7px 7px;
  background-color: $red2;
  color: $white;
  border-radius: 7px;

  &-table {
    width: 100%;
    &__head-row {
      display: flex;
      padding: 10px 12px;

      & > * {
        width: 50%;
      }
    }

    &__body {
      & .scrollbar-container {
        max-height: 500px;
      }
    }

    &__body-row {
      display: flex;
      margin-right: 12px;
      margin-bottom: 2px;
      border-radius: 6px;
      cursor: pointer;

      & > * {
        width: 50%;
        padding: 3px 10px;
      }

      &:nth-child(2n-1) {
        background-color: $red3;
      }
      &:nth-child(2n) {
        background-color: rgb($red3, 0.56);
      }

      &:hover {
        background-color: #ffbebe;
      }

      &.selected {
        background-color: $white;
        color: $red3;
      }
    }
  }
}
