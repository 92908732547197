@import "~assets/scss/1-variables/classic";

.misgrading-product {
  &__toolbar {
    margin-top: 20px;
  }

  & .button-block {
    display: flex;
    justify-content: flex-start;

    & .btn {
      margin-right: 10px;
    }
  }

  td.editable.green-bg {
    background-color: $green;
  }
}
