.products {
  border: none;


  & th,
  & td {
    padding-top: 1px;
    border: 1px solid black;
    word-break: break-all;
  }
}