.analitics-receivables {
  .action-table {
    margin-left: auto;
    margin-right: auto;
  }

  td.reset-expansion-style {
    padding: 0 !important;
  }

  .row-expansion-style {
    padding: 0 !important;
  }
}
