.navbar-nav .dropdown-menu {
  box-shadow: $box-shadow;
}

.dropdown .dropdown-menu.show {
  animation-name: dropdownAnimation;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

@keyframes dropdownAnimation {
  from {
    opacity: 0;
    transform: translateY(-8px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.dropdown-toggle:after {
  border: solid 1px black;
  border-radius: 7px;
  right: 9px;
  position: absolute;
  width: 22px;
  height: 22px;
  top: 6px;
  background: url(~assets/img/arrowDown.svg) no-repeat center center;
  transform: rotate(90deg);
}

.dropdown-item {
  transition: $transition-appearance-fast;
}

.dropdown-menu-lg {
  min-width: $dropdown-min-width * 2;
}

.dropdown .list-group .list-group-item {
  border-width: 0;
  border-bottom-width: 1px;
  margin-bottom: 0;

  &:first-child,
  &:last-child {
    border-radius: 0;
  }

  &:hover {
    background: $gray-100;
  }
}

.dropdown-menu {
  top: auto;
  min-width: 100%;
  padding: 11px;
  border: none !important;
  border-radius: 7px !important;
  box-shadow: 0 3px 6px rgb($black, 0.16) !important;

  & .dropdown-item {
    height: 36px;
    margin-bottom: 4px;
    padding-left: 13px;
    border-radius: 6px;
    border: solid 1px $gray-600;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.dropdown-menu-header {
  padding: $spacer * 0.75;
  text-align: center;
  font-weight: $font-weight-bold;
  border-bottom: 1px solid $gray-300;
}

.dropdown-menu-footer {
  padding: $spacer * 0.5;
  text-align: center;
  display: block;
  font-size: $font-size-sm;
}
