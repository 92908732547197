@import "~assets/scss/1-variables/classic";

.main-form {
  & .card-body {
    max-width: 600px;
  }

  & .row:not(:first-child) {
    margin-top: 6px;
  }

  &__border {
    border-bottom: solid 1px $gray-300 !important;
  }

  & .markup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 14px;
    border-radius: 5px;
    background-color: $cyan;
    font-weight: 500;
  }

  .printDoc {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}