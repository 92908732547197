@import "~assets/scss/1-variables/classic";

.active-resource {
  display: flex;
  align-items: center;
  width: 116px;
  height: 37px;
  padding: 12px 14px;
  margin-left: 10px;
  border-radius: 5px;
  background-color: $cyan;
  & .custom-checkbox {
    margin-right: 5px;
  }

  &__active {
    font-weight: 500;
    color: $green;
  }

  &__inactive {
    font-weight: 500;
    color: $red;
  }
}
