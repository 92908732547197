@import "~assets/scss/1-variables/classic";

.customer-info {
  border-bottom: solid 1px $gray-300 !important;
  & .card-body {
    max-width: 600px;
  }

  & .row:not(:first-child) {
    margin-top: 6px;
  }
}
