.modalChangeLog {
  min-width: 1000px;

  & td.center {
    text-align: center;
  }
}

.history-button {
  padding-left: 40px !important;
  background: url(~assets/img/history.svg) no-repeat 20px center;
  color: #1489ac !important;
  text-decoration: underline !important;

  &:hover {
    text-decoration: none !important;
  }
}

.changelog__text {
  max-width: 600px;
}
