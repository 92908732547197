@import "~assets/scss/1-variables/classic";

.moving-product {
  & td.editable.green-bg {
    background-color: $green;
  }

  & tr.error {
    background-color: $red4;
  }
}
