.customer-modal {
  .modal-body {
    & th {
      width: 35%;
      padding: 8px 20px 8px 8px !important;
      border-top: none !important;
      text-align: right;
    }

    & td {
      padding: 8px !important;
      border-top: none !important;
      border-bottom: none !important;
    }

    & tr:nth-child(2n) td {
      margin-bottom: 2px;
      border-radius: 6px;
      background: #f8f7f7;
    }
  }

  .edit-button {
    width: 35%;
    margin-top: 20px;
  }
  .with-diadoc {
    color: green;
  }
  .addresses {
    & td:nth-child(2) {
      max-width: 200px;
    }
    & td:nth-child(3) {
      max-width: 200px;
    }
  }
}
