.headTitle {
  text-align: left;

  td {
    margin: 0 !important;
    padding: 0;
    border-width: 0 0 1px 0 !important;
  }
}

.pageSeparator {
  td {
    margin: 0 !important;
    padding: 0;
    border: solid 1px transparent !important;
  }
}

tr.product {
  position: relative;
}

tr.product td {
  text-align: left;
}

td.right {
  text-align: right !important;
}

td.center {
  text-align: center !important;
}