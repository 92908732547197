@import "~assets/scss/1-variables/classic";

.nomenclature-modal {
  &__id {
    color: $gray-400;
  }

  & .row:not(:first-child) {
    margin-top: 6px;
  }

  & .prices {
    padding-top: 10px;
		margin-top: 10px;
    border-top: solid 1px $gray-300;
  }

  &__image {
    width: 100%;
    overflow: hidden;
    border-radius: 7px;

    & img {
      width: 100%;
    }
  }

  &__miniatures {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  &__miniature {
    position: relative;
    width: 94px;
    overflow: hidden;
    margin-top: 10px;
    border-radius: 7px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    &:not(:first-child) {
      margin-left: 7px;
    }

    &:nth-child(4n + 1) {
      margin-left: 0px;
    }

    & img {
      width: 100%;
    }

    &.selected {
      border: solid 1px $black;
    }
  }

  &__add {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 75px;
    margin-top: 10px;
    margin-left: 7px;
    border-radius: 7px;
    border: solid 1px $gray-300;
    outline: 0 !important;
  }

  &__remove {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    top: 0;
    width: 20px;
    height: 20px;
    padding: 0;
    border: none;
    outline: 0 !important;
    background-color: $black;
  }
}
