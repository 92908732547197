@import "~assets/scss/1-variables/classic";

.header-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 22px 0px 22px;
  & .h3 {
    margin-bottom: 0 !important;
  }

  &__left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 955px;

    & > * {
      margin-right: 12px;
    }
  }
}

.page-title{
	font-size: 20px;
	font-weight: 500;
	line-height: 23px;
}
