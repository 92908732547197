.head {
  width: 100%;
  margin: auto;

  .leftBlock {
    width: 86px;
  }

  .rightBlock {
    border-left: solid 1px;
  }

  .head-table {
    & span {
      border: 1px solid #000;
      padding: 1px 3px;
    }

    & tr:nth-child(3) {
      font-size: 6px;
    }
  }

  .docInfo {

    & td:nth-child(2),
    & td:nth-child(4) {
      text-align: center;
      border-bottom: 1px solid #000;
    }
  }

  .orgInfo {

    & th,
    & td {
      padding-top: 1px;
      border-bottom: 1px solid black;

      &:nth-child(1) {
        width: 16%;
        border: none !important;
      }
    }

    & tbody tr:last-child td {
      border: none;
    }

  }

  .annotation {
    text-align: right;
    font-size: 6px;
  }
}