@import "~assets/scss/1-variables/classic";

.status {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 36px;

  & .btn {
    overflow: hidden;
    padding-left: 17px;
    border-radius: 7px !important;
    background-color: $white;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;

    &:focus {
      background-color: $white;
    }
  }

  &__item {
    font-size: 14px;
    color: #fff !important;
  }
}
