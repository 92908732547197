.printInvoice {
  padding: 20px 20px 0 20px;
  display: none;

  & table {
    width: 100%;
    font-size: 9px;
    border-collapse: collapse;
    font-family: arial;
  }

  .head-table {

    max-width: 21cm;
    margin: auto;

    & tr:nth-child(2) {
      text-align: center;
    }

    & img {
      width: 150px;
    }
  }

  .title-table {
    max-width: 21cm;
    margin: auto;
    font-size: 18px;
    font-weight: 700;
    text-align: center;

    td {
      border: 2px solid #000;
      padding: 5px;
    }
  }


  .border-table {
    max-width: 21cm;
    margin: auto;
    border: 1px solid black;

    & th,
    & td {
      border: 1px solid black;
      padding: 3px;
    }

    & tr:nth-child(5) td {
      height: 45px;
    }

    td {
      vertical-align: top;
      position: relative;
      font-size: 11px;
    }

    td span {
      font-size: 7px;
      position: absolute;
      bottom: 2px;
      left: 3px;
    }

    caption {
      font-size: 10px;
      font-weight: 700;
      color: black;
      margin-bottom: 5px;
      caption-side: top;
    }

    tr:first-child td:nth-child(3) {
      border-bottom: none;
    }

    tr:nth-child(2) td:nth-child(2) {
      border-top: none;
    }
  }

  .payment-table {
    max-width: 21cm;
    margin: auto;
    font-size: 9px;

    caption {
      font-size: 14px;
      font-weight: 700;
      text-align: left;
      color: black;
      margin-bottom: 10px;
      caption-side: top;
    }

    td {
      padding: 7px 0;
    }

    td:last-child {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 11px;
      line-height: 1.2;
    }
  }

  .products-table {
    max-width: 21cm;
    margin: auto;
    border-bottom: 1px solid #000;

    & th,
    & td {
      border: 1px solid #000;
    }

    th {
      background-color: #fbe1bb;
      text-align: center;
      padding: 7px;
      font-size: 9px;
    }

    td {
      padding: 2px;
      font-size: 9px;
    }

    td:nth-child(1) {
      text-align: center;
    }

    td:nth-child(n+6) {
      text-align: right;
      white-space: nowrap;
    }

    .itogo,
    .nds,
    .itogoWithNds {
      font-weight: 700;
      font-size: 10px;
      text-align: right;
      white-space: nowrap;


      & td {
        border: none;

        &:first-child {
          text-align: right;
        }
      }
    }

    .itogoText {
      font-size: 10px;
      padding: 0;
      padding-bottom: 5px;

      & td {
        border: none;

        &:first-child {
          text-align: left;
        }
      }
    }

    .summText {
      font-size: 11px;
      font-weight: 700;
      padding: 0;
      padding-bottom: 5px;

      & td {
        border: none;

        &:first-child {
          text-align: left;
        }
      }
    }

  }


  .signatures-table {
    max-width: 21cm;
    margin: 12px auto 0;
    font-size: 8px;


    & tr:first-child td {
      font-size: 9px;
      font-weight: 700;
      padding-bottom: 10px;
    }

    & td {
      position: relative;
      width: 16.66%;
      text-align: center;
      padding: 5px 0;
    }

    & td span {
      font-size: 8px;
      border-top: 1px solid #000;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      width: calc(100% - 10px);
      text-align: center;
    }


    & tr:first-child td:nth-child(1),
    & tr:first-child td:nth-child(4) {
      text-align: left;
    }

    & tr:last-child td {
      text-align: left;
    }

  }

  .signature {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
  }

  .stamp {
    position: absolute;
    top: -70px;
    left: -50px;
    width: 150px;
  }

  .importantly-table {
    max-width: 21cm;
    margin: auto;
    text-align: center;

    & td {
      border: 1px solid #000;
      font-weight: 700;
    }

    & tr:first-child td {
      border: 1px solid #000;
      font-weight: 700;
    }

    & tr:last-child td {
      padding: 5px;
      font-weight: 700;
    }
  }

}


@media print {

  .printInvoice {
    display: block;
  }

}