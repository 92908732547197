.orders-button_link {
  width: 100%;
  outline: none !important;
  background: none;
  border: none;
  text-align: left;

  &:hover {
    color: #2f878c;
    text-decoration: underline;
  }
}
