@import "~assets/scss/1-variables/classic";

.return-to-provider-one {
  &__form {
    width: 900px;
  }

  & .row:not(:first-child) {
    margin-top: 12px;
  }

  &__button-save {
    border-bottom: solid 1px $gray-300 !important;
  }

  &__date-block {
    display: flex;
    align-items: center;
    margin-left: 37px;

    .date {
      width: 110px;
    }

    & .form-label {
      margin-right: 14px;
    }
  }
}
