.doc{
    display: flex;
    justify-content: baseline;
    align-items: center;
    margin-top: 8px;

    & input {
        margin: 0 5px 0 5px;

        &:last-child {
            margin-right: 0;
        }
    }
}