.order-picking-one {
  &__form {
    width: 600px;
  }

  & .row:not(:first-child) {
    margin-top: 12px;
  }

  .button-block {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  .end-picking {
    width: 155px;
    margin-left: 12px;

    svg {
      margin-right: 8px;
    }
  }
}

@media print {
  .order {
    display: none;
  }
}