.progress-sm {
  height: $progress-height-sm;
}

.progress-lg {
  height: $progress-height-lg;
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba($orange, 0.15) 25%,
    rgba($orange, 0.65) 25%,
    transparent 50%,
    rgb($orange, 0.15) 50%,
    rgba($orange, 0.15) 75%,
    rgba($orange, 0.65) 75%,
    transparent
  );
  color: $black;
}
