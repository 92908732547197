.content {
  position: relative;
  padding: 15px 0 110px 0;
  margin: 20px 20px 20px 0;
  flex: 1;
  overflow: hidden;
  border-radius: 10px;

  @include media-breakpoint-down(md) {
    width: 100vw;
    max-width: 100vw;
  }

  @include media-breakpoint-down(lg) {
    padding: $spacer * 1.5;
  }
}
