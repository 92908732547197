@import "~assets/scss/1-variables/classic";

.bar-chart {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &-green {
    display: block;
    width: 10px;
    height: 7px;
    margin-right: 6px;
    margin-top: 4px;
    background: url(~assets/img/barChartGreen.svg) no-repeat left center;
  }

  &-red {
    display: block;
    width: 10px;
    height: 7px;
    margin-right: 6px;
    margin-top: 4px;
    background: url(~assets/img/barChartRed.svg) no-repeat left center;
  }
}
