@import "~assets/scss/1-variables/classic";

.close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 25px;
  height: 25px;
  border: none;
  background: $black;
  border-radius: 50%;

  &:focus, &:active {
    border: none;
  }
}
