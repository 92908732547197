@import "~assets/scss/1-variables/classic";

.dashboard {
  &__buttons-block {
    &>* {
      display: inline-block;
      margin-right: 40px;
    }
  }

  &__search-result {
    display: flex;
    padding-bottom: 20px;
    // border-bottom: solid 1px $gray-300 !important;
  }

  &__search-left {
    min-width: 330px !important;
  }

  &__search-right {
    width: 100%;
    align-items: stretch;
  }

  & .errors {
    color: red;
  }

  & h3 {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.countGroup {
  display: flex;

  .count {
    width: 70px;
    margin-right: 8px;
    padding-right: 4px;
  }
}

.results {
  & .progress {
    width: calc(100% - 40px);
  }

  .reserve {
    font-size: 10px;
  }

  & h3 {
    font-size: 20px;
    font-weight: 500;
  }
}

.apiResults {
  display: flex;
  gap: 20px;
  font-size: 11px;
}

.apiResult {
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 2px 5px;
  border-radius: 3px;
  background-color: $gray-primary;
}