@import "~assets/scss/1-variables/classic";

.contracts {
  position: relative;
  border-bottom: solid 1px $gray-300 !important;

  &__header {
    position: absolute;
    width: 100%;
    height: 43px;
    background-color: $cyan;
  }

  & .table {
    width: 1400px;
    margin-left: 22px;
    position: relative;

    & td,
    & th {
      padding-right: 20px;
      padding-left: 20px;
    }

    & td {
      border-bottom: solid 1px $gray-300 !important;
    }

    & td:not(:last-child) {
      position: relative;

      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 0;
        height: 20px;
        right: 0;
        top: calc(50% - 10px);
        border-left: solid 1px $gray-300 !important;
      }
    }
  }

  & .additional {
    width: 600px;
    position: relative;
  }

  & .card-body {
    max-width: 600px;
  }

  & .row:not(:first-child) {
    margin-top: 6px;
  }

  &__save {
    margin-right: 12px;
  }

  & .inactive {
    opacity: 0.4;
  }

  & .table-actions-block {
    display: flex;
    align-items: center;

    & .action-table {
      margin-right: 12px;
    }

    & .download {
      margin-left: 25px;
    }
  }

  .buttons {
    margin-top: 20px;
  }

  .downloadScan {
    color: black;
  }
}