.printLabel {
  display: none;
  width: 610px;
  height: 545px;
  font-size: 13px;
  border-bottom: solid 1px gray;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;

  td {
    padding: 6px 10px;
    overflow: hidden;
  }

  .title {
    font-size: 26px;
    font-weight: 900;
  }

  .customerName {
    font-size: 18px;
    font-weight: 900;
  }

  .orderNumber {
    font-size: 26px;
    font-weight: 700;
  }

  .pickup {
    font-size: 50px;
    font-weight: 600;
    text-align: center;
    margin-top: 30px;
  }
}

@media print {
  .printLabel {
    display: block;
  }
}
