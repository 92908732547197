.printUtd {
  width: 1080px;
  padding: 20px 20px 0 20px;
  //position: absolute;
  background: white;
  top: 0;

  & table {
    width: 100%;
    font-size: 9px;
    border-collapse: collapse;
    font-family: arial;
    line-height: 1;
  }

  & td,
  & th {
    vertical-align: top;
  }

}

@media print {
  .printUtd {
    display: block;
  }
}