@import "~assets/scss/1-variables/classic";

.back-to {
  .btn-secondary {
    font-size: 13px !important;
    color: $gray-600 !important;

    &:hover,
    &:focus,
    &:active {
      border-color: $orange !important;
      color: $orange !important;
      background-color: $white !important;
    }
  }
}
