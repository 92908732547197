@import "~assets/scss/1-variables/classic";

.select-customer {
  .with-button {
    position: relative;

    & .react-select__dropdown-indicator {
      margin-right: 28px;
    }
  }

  .open-client-button {
    position: absolute;
    right: 4px;
    top: calc(50% - 11px);
    width: 22px;
    height: 22px;
    padding: 0;
    border-radius: 7px;
    border: solid 1px $black;
    outline: 0;
  }
}
