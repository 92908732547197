@import "~assets/scss/1-variables/classic";

.entering-balance-product {
  &__toolbar {
    margin-top: 20px;
  }

  & .button-block {
    display: flex;
    justify-content: flex-start;

    & .btn {
      margin-right: 10px;
    }
  }

  & tr.error {
    background-color: $red4;
  }
}