@media print {

  @page wide {
    size: a4 landscape !important;
  }


  .landscape,
  .landscape * {
    page: wide !important;
    visibility: visible !important;
  }

}