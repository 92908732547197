@import "~assets/scss/1-variables/classic";

.orderform {
  & h3 {
    font-size: 20px;
    font-weight: 500;
  }

  .select_address > div {
    width: calc(100% - 40px);
    flex-shrink: 1;
  }
}
